<template>
  <div>
    <b-modal
      id="add-new-insemination-sidebar"
      centered
      :visible="isAddNewInseminationSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :ok-title="$t('ok')"
      ok-only
      :title="$t('inseminationAdd')"
      @ok="onSubmit(blankInsemination)"
      @hidden="resetForm"
      @change="
        (val) => $emit('update:is-add-new-insemination-sidebar-active', val)
      "
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- methods -->

          <validation-provider
            #default="validationContext"
            name="method"
            rules="required"
          >
            <b-form-group :label="$t('method')" label-for="method">
              <v-select
                v-model="blankInsemination.methodid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="methods"
                class="w-100"
                :reduce="(val) => val.value"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- statuses -->

          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group :label="$t('status')" label-for="status">
              <v-select
                v-model="blankInsemination.statusid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statuses"
                class="w-100"
                :reduce="(val) => val.value"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- spermas -->

          <validation-provider
            #default="validationContext"
            name="sperma"
            rules="required"
          >
            <b-form-group :label="$t('sperma')" label-for="sperma">
              <v-select
                v-model="blankInsemination.spermaid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="spermas"
                class="w-100"
                :reduce="(val) => val.value"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- inseminationdate -->
          <validation-provider
            #default="validationContext"
            name="inseminationdate"
            rules="required"
          >
            <b-form-group
              :label="$t('inseminationDateTime')"
              label-for="inseminationdate"
            >
              <b-form-datepicker
                v-model="blankInsemination.inseminationdate"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="examiner"
            rules="required"
          >
            <b-form-group :label="$t('examiner')" label-for="examiner">
              <b-form-input
                id="examiner"
                v-model="blankInsemination.examiner"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- cost -->
          <validation-provider #default="validationContext" name="cost">
            <b-form-group :label="$t('cost')" label-for="cost">
              <b-form-input
                id="cost"
                v-model="blankInsemination.cost"
                autofocus
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- description -->
          <validation-provider #default="validationContext" name="description">
            <b-form-group :label="$t('description')" label-for="description">
              <b-form-input
                id="description"
                v-model="blankInsemination.description"
                autofocus
                type="text"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, getCurrentInstance } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";

import vSelect from "vue-select";
export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewInseminationSidebarActive",
    event: "update:is-add-new-insemination-sidebar-active",
  },
  props: {
    isAddNewInseminationSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedAnimal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },

  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const dayJs = dayjs.extend(relativeTime);
    const methods = ref([]);
    const statuses = ref([]);
    const spermas = ref([]);
    const blankInseminationData = {
      id: 0,
      companyid: getUserData().companyid,
      plantid: store.state.app.selectedPlantId,
      animalid: props.selectedAnimal.id,
      spermaid: 0,
      methodid: 0,
      statusid: 0,
      inseminationdate: dayJs().format(),
      description: "",
      cost: 0,
    };

    const blankInsemination = ref(
      JSON.parse(JSON.stringify(blankInseminationData))
    );
    const resetblankInsemination = () => {
      blankInsemination.value = JSON.parse(
        JSON.stringify(blankInseminationData)
      );
    };

    const fetchMethods = async () => {
      methods.value = [];
      var data = await store.dispatch("animalsModule/fetchInseminationMethods");
      data.map((x) => methods.value.push({ label: x.methodname, value: x.id }));
    };
    const fetchStatus = async () => {
      statuses.value = [];
      var data = await store.dispatch(
        "animalsModule/fetchInseminationStatuses",
        store.state.app.selectedPlantId
      );
      data.map((x) =>
        statuses.value.push({ label: x.statusname, value: x.statusid })
      );
    };
    const fetchSpermas = async () => {
      spermas.value = [];
      var data = await store.dispatch(
        "definitionsModule/fetchSpermas",
        store.state.app.selectedPlantId
      );
      data
        .filter(
          (x) => x.irkid == store.state.animalsModule.selectedAnimal.breedid
        )
        .map((x) =>
          spermas.value.push({ label: x.spermaresmino, value: x.id })
        );
    };

    fetchMethods();
    fetchStatus();
    fetchSpermas();

    const onSubmit = (insemination) => {
      store
        .dispatch("animalsModule/addAnimalInsemination", insemination)
        .then((res) => {
          if (res.statusdata == "-2") {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("animalAlreadyAdded"),
                icon: "AlertCircleIcon",
                variant: "alert",
              },
            });
          } else if (res.statusdata == "1") {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("animalAdded"),
                icon: "AlertCircleIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-add-new-insemination-sidebar-active", false);
          } else {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("animalInseminationNotAdded"),
                icon: "AlertCircleIcon",
                variant: "success",
              },
            });
          }
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetblankInsemination
    );
    return {
      blankInsemination,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      methods,
      statuses,
      spermas,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-insemination-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl-scoped {
  width: 70rem;
}
</style>
