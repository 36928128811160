<template>
  <div>
    <b-modal
      id="eedit-insemination-sidebar"
      centered
      :visible="isEditInseminationSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :ok-title="$t('ok')"
      ok-only
      :title="$t('editAnimalInsemination')"
      @ok="onSubmit(blankInseminationData)"
      @hidden="formValidation(resetblankinsemination).resetForm"
      @change="(val) => changed(val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="formValidation(resetblankinsemination).resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />

          <!-- methods -->

          <validation-provider
            #default="validationContext"
            name="method"
            rules="required"
          >
            <b-form-group :label="$t('method')" label-for="method">
              <v-select
                v-model="blankInseminationData.methodid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="methods"
                class="w-100"
                :reduce="(val) => val.value"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- statuses -->

          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group :label="$t('status')" label-for="status">
              <v-select
                v-model="blankInseminationData.statusid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statuses"
                class="w-100"
                :reduce="(val) => val.value"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- spermas -->

          <validation-provider
            #default="validationContext"
            name="sperma"
            rules="required"
          >
            <b-form-group :label="$t('sperma')" label-for="sperma">
              <v-select
                v-model="blankInseminationData.spermaid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="spermas"
                class="w-100"
                :reduce="(val) => val.value"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- inseminationdate -->
          <validation-provider
            #default="validationContext"
            name="inseminationdate"
            rules="required"
          >
            <b-form-group
              :label="$t('inseminationDateTime')"
              label-for="inseminationdate"
            >
              <b-form-datepicker
                v-model="blankInseminationData.inseminationdate"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="examiner"
            rules="required"
          >
            <b-form-group :label="$t('examiner')" label-for="examiner">
              <b-form-input
                id="eexaminer"
                v-model="blankInseminationData.examiner"
                autofocus
                :state="
                  formValidation(resetblankinsemination).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- cost -->
          <validation-provider #default="validationContext" name="cost">
            <b-form-group :label="$t('cost')" label-for="cost">
              <b-form-input
                id="ecost"
                v-model="blankInseminationData.cost"
                autofocus
                type="number"
                :state="
                  formValidation(resetblankinsemination).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- description -->
          <validation-provider #default="validationContext" name="description">
            <b-form-group :label="$t('description')" label-for="description">
              <b-form-input
                id="edescription"
                v-model="blankInseminationData.description"
                autofocus
                type="text"
                :state="
                  formValidation(resetblankinsemination).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    BFormDatepicker,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditInseminationSidebarActive",
    event: "update:is-edit-insemination-sidebar-active",
  },
  props: {
    isEditInseminationSidebarActive: {
      type: Boolean,
      required: true,
    },
    insemination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankInseminationData: { ...this.insemination },
      imgStr: "",
      formValidation: formValidation,
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
      inseminationtypeOptions: [],
      selectedInseminationType: null,
      methods: [],
      statuses: [],
      spermas: [],
    };
  },
  mounted() {
    this.fetchMethods();
    this.fetchStatus();
    this.fetchSpermas();
  },
  async created() {},
  methods: {
    async changed(val) {
      this.resetblankinsemination();
      this.$emit("update:is-edit-insemination-sidebar-active", val);
    },
    onSubmit() {
      store
        .dispatch(
          "animalsModule/updateAnimalInsemination",
          this.blankInseminationData
        )
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-insemination-sidebar-active", false);
        });
    },
    async fetchMethods() {
      this.methods = [];
      var data = await store.dispatch("animalsModule/fetchInseminationMethods");
      data.map((x) => this.methods.push({ label: x.methodname, value: x.id }));
    },
    async fetchStatus() {
      this.statuses = [];
      var data = await store.dispatch(
        "animalsModule/fetchInseminationStatuses",
        store.state.app.selectedPlantId
      );
      data.map((x) =>
        this.statuses.push({ label: x.statusname, value: x.statusid })
      );
    },
    async fetchSpermas() {
      this.spermas = [];
      var data = await store.dispatch(
        "definitionsModule/fetchSpermas",
        store.state.app.selectedPlantId
      );
      data
        .filter(
          (x) => x.irkid == store.state.animalsModule.selectedAnimal.breedid
        )
        .map((x) => this.spermas.push({ label: x.spermaresmino, value: x.id }));
    },
    async resetblankinsemination() {
      this.blankInseminationData = {
        ...this.insemination,
        inseminationid: this.insemination.id,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-insemination-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
