import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

import dayjs from "dayjs";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useInseminationList() {
  // Use toast
  const toast = useToast();

  const refInseminationListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "inseminationdate",
      label: VueI18n.t("inseminationDateTime"),
      sortable: false,
      formatter: (val) =>
        `${dayjs(val).locale(`${VueI18n.locale}`).format("DD MMMM YYYY dddd")}`,
    },
    { key: "methodname", label: VueI18n.t("method"), sortable: true },
    { key: "statusname", label: VueI18n.t("status"), sortable: true },
    {
      key: "examiner",
      label: VueI18n.t("examiner"),
      sortable: false,
    },
    {
      key: "cost",
      label: VueI18n.t("cost"),
      sortable: false,
    },
    {
      key: "description",
      label: VueI18n.t("description"),
      format: (val) => {
        return `${val.toString().substring(0, 4)}...`;
      },
      sortable: false,
    },
    { key: "delete", label: VueI18n.t("delete") },
    { key: "edit", label: VueI18n.t("edit") },
  ];
  const perPage = ref(10);
  const totalInsemination = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const allInseminations = ref([]);
  const filteredInseminations = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredInseminations.value.length;
    const to = perPage.value * currentPage.value < localItemsCount;

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: to
        ? perPage.value * (currentPage.value - 1) + perPage.value
        : localItemsCount,
      of: allInseminations.value.length,
    };
  });

  const refetchData = () => {
    if (searchQuery.value) {
      filteredInseminations.value = allInseminations.value.filter((x) =>
        x.inseminationname
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase())
      );
    } else {
      filteredInseminations.value = allInseminations.value;
    }
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchInseminations = async (ctx) => {
    var insemination = await store
      .dispatch("animalsModule/fetchAnimalInsemination", {
        animalid: ctx,
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", {
              value: VueI18n.t("inseminations"),
            }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allInseminations.value = insemination;
    filteredInseminations.value = [...insemination];
    refetchData();

    return insemination;
  };

  const deleteInsemination = (ctx) => {
    store
      .dispatch("animalsModule/deleteAnimalInsemination", ctx.inseminationid)
      .then((response) => {
        fetchInseminations(ctx.animalid);
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("inseminations") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", {
              value: VueI18n.t("inseminations"),
            }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------------------- UI -------------------------*
  // *===============================================---*

  return {
    fetchInseminations,
    tableColumns,
    perPage,
    currentPage,
    totalInsemination,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInseminationListTable,
    deleteInsemination,
    filteredInseminations,
    allInseminations,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
